import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import flex, {ResourceGroup, Resource} from '../../flex';
import {configurableIcon} from '../icons';
import OptionConfigurations from './OptionConfigurations';
import {SelectResourceButton, PriceTag} from './../Buttons/styled';

import {SecondaryButton, DefaultButton} from './../Buttons';

export default (props: {
  action: 'select' | 'edit',
  resourceGroup: ResourceGroup,
  resource?: Resource | undefined,
  closeHandler?: () => void
}) => {



  const [action, setAction] = useState<'select' | 'edit'>(props.action);
  const [resourceGroup, setResourceGroup] = useState<ResourceGroup>(props.resourceGroup);
  const [resource, setResource] = useState<Resource | undefined>(props.resource);

  type resourceOptionsType = {[key:string]: any}[];
  const [resourceOptions, setResourceOptions] = useState<resourceOptionsType | undefined>(); // used as a state to trigger re-render


  useEffect(() => {
    if (action === 'edit') {
      const reload = () => {
        if (resource) {
          let opts = [...resource.listEnabledOptions()];
          setResourceOptions(opts);
        }

      }
      flex.subscribe(flex.events.ResourceEnabled, reload);
      return () => {
        flex.unSubscribe(flex.events.ResourceEnabled, reload);
      }
    }

  }, [action, resource])

  if(!resourceGroup) return <h1>Loading</h1>

  if (action === 'edit' && !resource) return <h1>Error!</h1>

  else if (action === 'select') {



    let selectableResources = resourceGroup.listAvailableResources().filter( (resource) => {
      return !flex.configurableLimitReached("Resource", resource._configId, resourceGroup.getUniqueId())
    });

    return (
      <>
        
        <h3>Valitse palvelut</h3>



        {selectableResources.map( (resource) => {
          return (
            <SelectResourceButton
              onClick={() => {
                let r = flex.createResource(resource._configId, resourceGroup.getUniqueId())
                try {
                  r.save();
                  setResource(r as Resource);
                  setAction('edit');
                }
                catch(e) { 
                  console.log(e)    
                  flex.publish(flex.events.Dialog, {
                    title: "Palvelua ei voi lisätä",
                    content: e.message,
                    confirmText: "OK",
                    canceltext: null
                  }); 
                }
              }}

              startIcon={configurableIcon(resource._configId)}>
              {resource.Name}
          </SelectResourceButton>
          )
        })}

        {selectableResources.length === 0 && (
          <p>Ei enempää saatavilla olevia palveluita :(</p>
        )}

      </>
    )
  }
  else {
    if(!resource) return <h1>Error</h1>
    else {
      return (
        <>
          <SecondaryButton 
            style={{float:"right"}}
            variant="outlined"
            onClick={ () => {

              flex.publish(flex.events.Dialog, {
                title: `Haluatko poistaa ${resource.getConfig('Name')}?`,
                confirmText: "Kyllä, poista",
                cancelText: "Peruuta",
                confirm: () => {
                  flex.disableConfigurable('Resource', resource)
                  if (props.closeHandler) props.closeHandler();
                },
                cancel : () => {
                  // nothing to do
                }
              })
            }}
            startIcon={<DeleteIcon/>}
            >Poista</SecondaryButton>

          <Grid container
            alignItems="center"
            justify="space-between"
            wrap="nowrap"
          
          >
            <Grid item>
              <h4>{resource.getConfig('Name')}</h4>
            </Grid>
            <Grid item>
              <PriceTag>
                {resource.calculatePrice()} €
              </PriceTag>
            </Grid>
          </Grid>

          {resource.getConfig('Description') && (
            <Grid container
            style={{margin:"6px 0px 12px 0px"}}
            alignItems="center"
            justify="space-between"
            wrap="nowrap"
            >
              <Grid item>
                <div dangerouslySetInnerHTML={{__html: resource.getConfig('Description') as string}} />
              </Grid>
            </Grid>
          )}


          <OptionConfigurations configurable={resource} />

          <DefaultButton style={{marginTop:"20px"}}  onClick={ () => {
            resource.save();
            if (props.closeHandler) props.closeHandler();
            }}>Tallenna ja sulje</DefaultButton>

        </>
      )
    }
  }

}