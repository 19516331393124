import Flex from './src/Flex';
import createUniqueId from './src/uniqueId';
import Configurable from './src/Configurable';
import ResourceGroup from './src/ResourceGroup';
import Resource from './src/Resource';

const flex = new Flex();

export default flex;
export {createUniqueId, Configurable, ResourceGroup, Resource}


