import { ConfigError } from './Errors';
import {InputConfigJsonType, FlexConfig, FlexState} from './types'; 
import Flex from './Flex'; 
const YAML = require('yaml');

/**
 * Get and transform configuration object
 * This could be moved to external API
 */
class Repo {

  // currently there is no logic to automatically reset state when config yml is modified, this localstorage key needs to changed to force reset state
  localStorageKey = '_flex_202206'; 

  async getNewConfiguration(): Promise<FlexConfig> {
    const res = await fetch("/flex/config.yml");
    const data = await res.text();
    const json: InputConfigJsonType = YAML.parse(data);
  
    let configurables: any = {};
    ["ResourceGroup", "Resource"].forEach( (configurableType) => {
      configurables[configurableType] = [];
      Object.keys(json[configurableType]).forEach( (configId) => {
        if (json[configurableType][configId].Options) {
          let options: object[] = [];
          Object.keys(json[configurableType][configId].Options).forEach( (optionId) => {
            let values: object[] = [];
            if (json[configurableType][configId].Options[optionId].Values) {
              Object.keys(json[configurableType][configId].Options[optionId].Values).forEach( (valueId) => {
                values.push({
                  _configId: valueId,
                  ...json[configurableType][configId].Options[optionId].Values[valueId]
                });
              });
              json[configurableType][configId].Options[optionId].Values = values;
            }
            options.push({
              _configId: optionId,
              ...json[configurableType][configId].Options[optionId]
            });
          });
          json[configurableType][configId].Options = options;
        }
        
        configurables[configurableType].push({
          _configurableType : configurableType.replace(/s$/i, ''),
          _configId: configId,
          ...json[configurableType][configId]
        });
      });
    });

    await new Promise( (resolve) => {
      setTimeout( () => {
        resolve(null);
      }, 2000)
    });

    return {
      Version: json.Version,
      ResourceGroup : configurables["ResourceGroup"],
      Resource : configurables["Resource"],
    };

  }
  async getFlexState(): Promise<FlexState> {


    let json: string | null = localStorage.getItem(this.localStorageKey)
    if (json !== null) {
      return JSON.parse(json);
    }
    else {
      return {
        ResourceGroup: [],
        Resource: [],
        config: await this.getNewConfiguration()
      }; 
    }
  }
  async storeFlexState(flex: Flex): Promise<void> {

    console.log( 'setting item' )
    console.log(flex)
    localStorage.setItem(this.localStorageKey, JSON.stringify(flex))
    return;
  }
}
export default new Repo();
