/*
  Does not support range
*/

import React, {useState} from 'react';
import Slider from '@material-ui/core/Slider';

import styled from 'styled-components';

import TextField from '@material-ui/core/TextField';

const PrettoSlider = styled(Slider)`
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
`

const RangeSlider = (props: {
  defaultValue: number,
  title: string,
  max: number | undefined,
  min: number | undefined,
  step: number | undefined,
  onChangeCommitted: (event: any, value: number) => void
}) => {
  const [val, setVal] = useState(props.defaultValue || 10)
  const resetState = () => {
    setVal(props.defaultValue || 10)
  }

  return (
    <div style={{width:"100%"}}>
      {props.title}
      <PrettoSlider 
        valueLabelDisplay="auto" aria-label="pretto slider" aria-labelledby="continuous-slider"
        defaultValue={props.defaultValue || 10}
        value={val} 
        max={props.max || 100}
        min={props.min || 1}
        marks={true}
        step={props.step || 1}
        onChange={(ev, newVal) => {setVal(newVal as number)}}
        onChangeCommitted={(event, newVal) => {
          if(typeof props.onChangeCommitted === 'function') {
            let success = props.onChangeCommitted(event, newVal as number);
            // if(success === false) resetState()
          }
        }}
      />
    </div>
  )
}
export default RangeSlider;