import React, {useEffect, useState} from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Zoom from '@material-ui/core/Zoom'
import flex from '../flex'


export default () => {

  const [message, setMessage] = useState<string | null>(null)
  const [severity, setSeverity] = useState<'info' | 'error' | 'warning' | 'success'>('info')
  const [autoHideDuration, setAutoHideDuration] = useState<number>(5000);

  const setNotification = (data: any) => {
    let msg = data.message || null;
    let svrt = data.severity || 'info';
    let ahd = data.autoHideDuration || 5000;
    setMessage(msg);
    setSeverity(svrt);
    setAutoHideDuration(ahd)

  }

  useEffect(() => {
    const createSuccessNotification = (data: any) => {
      setNotification({message: "Tallennettu" , severity: "success", autoHideDuration: 1000});
    }
    flex.subscribe(flex.events.ResourceGroupDisabled, createSuccessNotification)
    flex.subscribe(flex.events.ResourceDisabled, createSuccessNotification)
    flex.subscribe(flex.events.ResourceEnabled, createSuccessNotification)
    flex.subscribe(flex.events.Notification, setNotification)
    return () => {
      flex.unSubscribe(flex.events.ResourceGroupDisabled, createSuccessNotification)
      flex.unSubscribe(flex.events.ResourceDisabled, createSuccessNotification)
      flex.unSubscribe(flex.events.ResourceEnabled, createSuccessNotification)
      flex.unSubscribe(flex.events.Notification, setNotification)
    }
  }, [message, severity]);
 
  if (!message) return <div style={{display:"none"}}></div>

  return (
    <Zoom in={true}>
    <Snackbar 
      open={true} 
      autoHideDuration={autoHideDuration} 
      onClose={(event, reason) => {
        if (reason === 'clickaway') return;
        else return setMessage(null)
      }}
    >
      <Alert 
        onClose={() => {
          setMessage(null)
        }} 
        severity={severity}
      >
      {message}
    </Alert>
    </Snackbar>
    </Zoom>
  )
}

