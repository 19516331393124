import styled from 'styled-components';
import Button from '@material-ui/core/Button';


export const YellowButton = styled(Button)`
  && {
    background-color: #FFB500;
    color: #40310a;
    font-weight:700;
    padding:6px 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    &:hover {
      background-color: #ffc400;
    }
    border-radius:8px;
  }
`;
export const DefaultButton = styled(Button)`
  && {
    background-color: #007BFF;
    color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    &:hover {
      background-color: #5469d4;
    }

  }
`;
export const SecondaryButton = styled(Button)`

`;
export const SelectResourceButton = styled(Button)`
  && {
    width:100%;
    text-align:left;
    justify-content:flex-start;
  }
  svg {
    height:32px;
    width:32px;
  }
`;

export const PriceTag = styled.div<{
  size?: number,
  color?: string,
  background?: string
}>`
  display: inline-block;
  width: auto;
  height: ${props => (props.size) ? props.size + 'px' : '26px'};
  background-color: ${props => (props.background) ? props.background : '#343A40'};
  -webkit-border-radius: 3px 4px 4px 3px;
  -moz-border-radius: 3px 4px 4px 3px;
  border-radius: 3px 4px 4px 3px;
  border-left: 1px solid #343A40;
  margin-left: 0px;
  position: relative;
  color: ${props => (props.color) ? props.color : '#fff'};
  font-weight: bold;
  font-size: ${props => (props.size) ? (props.size - 10) + 'px' : '16px'};
  line-height: ${props => (props.size) ? props.size + 'px' : '26px'};
  padding: 0 6px 0 8px;
  white-space: nowrap;
  box-shadow:0px 1px 1px #666;
  &:before {
    content: "";
    position: absolute;
    display: block;
    left: ${props => (props.size) ? '-' + (props.size / 2) + 'px' : '-13px'};
    width: 0;
    height: 0;
    border-top: ${props => (props.size) ? (props.size / 2) + 'px' : '13px'} solid transparent;
    border-bottom: ${props => (props.size) ? (props.size / 2) + 'px' : '13px'} solid transparent;
    border-right: ${props => (props.size) ? (props.size / 2) + 'px' : '13px'} solid ${props => (props.background) ? props.background : '#343A40'};
  }
  &:after {
    content: "";
    background-color: ${props => (props.color) ? props.color : '#fff'};
    border-radius: 50%;
    width: ${props => (props.size) ? (props.size / 5) + 'px' : '4px'};
    height: ${props => (props.size) ? (props.size / 5) + 'px' : '4px'};
    display: block;
    position: absolute;
    left:  ${props => (props.size) ? '-' + (props.size / 3.5) + 'px' : '-6px'};
    top: ${props => (props.size) ?  (props.size / 2.4) + 'px' : '11px'};
  }
}
`;

