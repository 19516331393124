import React, {useEffect, useState} from 'react';


import Button from '@material-ui/core/Button';
import flex from '../flex'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default () => {

  const [title, setTitle] = useState<string | null>(null)
  const [content, setContent] = useState<string | null>(null)
  const [confirmText, setConfirmText] = useState<string>("OK")
  const [cancelText, setCancelText] = useState<string>("Cancel")
  const [confirmFunc, setConfirmFunc] = useState<any>(null)
  const [cancelFunc, setCancelFunc] = useState<any>(null)

  const setDialog = (data: any) => {
    setTitle(data.title || null);
    setContent(data.content || null);
    setConfirmText(data.confirmText || null);
    setCancelText(data.cancelText || null);
    if (typeof data.confirm === 'function') {
      // you need to provide function that returns function because React executes anonymous functions on first render
      setConfirmFunc(() => () => data.confirm());
    }
    if (typeof data.cancel === 'function') {
      setCancelFunc(() => () => data.cancel());
    }
  }

  useEffect(() => {

    flex.subscribe(flex.events.Dialog, setDialog)
    return () => {
      flex.unSubscribe(flex.events.Dialog, setDialog)
    }
  }, [title, content, confirmText, cancelText]);
 
  if (!title) return <div style={{display:"none"}}></div>

  return (
    <Dialog
      open={true}
      onClose={() => {
        setTitle(null)
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={() => {
          setTitle(null)
          if (typeof cancelFunc === 'function') cancelFunc()
        }} color="primary">
          {cancelText}
        </Button>
        <Button onClick={() => {
          setTitle(null)
          if (typeof confirmFunc === 'function') confirmFunc()
        }} color="primary" autoFocus>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>



  )

  

 
  
  /*

  else {
    return (
      <Snackbar 
        open={true} 
        autoHideDuration={6000} 
        onClose={() => {
          setMessage(null)
        }}
      >
        <Alert 
          onClose={() => {
            setMessage(null)
          }} 
          severity={severity}
        >
        {message} 
        
        {console
        .log('render')}
        {console.log(message)}
        {console.log(severity)}
      </Alert>
      </Snackbar>
    )
  }
  */

}

