import React, {useEffect, useState} from 'react';
import TagManager from 'react-gtm-module'
import flex from './../flex'

TagManager.initialize({
  gtmId: 'GTM-TDX8KVZ',
})



export default () => {

  const track = (data: any) => {
    console.log('DATALAYER', data);
    console.log(data.constructor.name);
  }

  useEffect(() => {
    flex.subscribe(flex.events.LogEvent, (event) => {
      if (event.event === 'FormOpened' || event.event === 'FormSubmitted' ) {
        TagManager.dataLayer({
          dataLayer: {event: event.event}
        })
      }
      if (event.event === 'FormSubmitError') {
        TagManager.dataLayer({
          dataLayer: {event: event.event, errorDetail: event.errorDetail}
        })
      }
    })

    flex.subscribe(flex.events.ResourceGroupDisabled, (resourceGroup) => {
      TagManager.dataLayer({
        dataLayer: {
          event: "ResourceGroupDisabled",
          configurableName: resourceGroup.getConfig('Name'),
          configurableId: resourceGroup.getUniqueId()
        }
      })
    })
    flex.subscribe(flex.events.ResourceGroupEnabled, (resourceGroup) => {
      TagManager.dataLayer({
        dataLayer: {
          event: "ResourceGroupEnabled",
          configurableName: resourceGroup.getConfig('Name'),
          configurableId: resourceGroup.getUniqueId()
        }
      })
    })
    flex.subscribe(flex.events.ResourceDisabled, (resource) => {
      TagManager.dataLayer({
        dataLayer: {
          event: "ResourceDisabled",
          configurableName: resource.getConfig('Name'),
          configurableId: resource.getUniqueId()
        }
      })
    });
    flex.subscribe(flex.events.ResourceEnabled, (resource) => {
      TagManager.dataLayer({
        dataLayer: {
          event: "ResourceEnabled",
          configurableName: resource.getConfig('Name'),
          configurableId: resource.getUniqueId()
        }
      })
    });


    /*

    return () => {
      flex.unSubscribe(flex.events.ResourceGroupDisabled, track)
      flex.unSubscribe(flex.events.ResourceDisabled, track)
      flex.unSubscribe(flex.events.ResourceEnabled, track)
    }
    */
  }, []);


  return (
    <>
    </>
  )
}