import React from 'react';
import BlockIcon from '@material-ui/icons/Block';
import SmartPhoneIcon from '@material-ui/icons/Smartphone';
import LaptopIcon from '@material-ui/icons/Laptop';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import VideocamIcon from '@material-ui/icons/Videocam';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import PrintIcon from '@material-ui/icons/Print';
import SecurityIcon from '@material-ui/icons/Security';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import WorkIcon from '@material-ui/icons/Work';
import SpeakerIcon from '@material-ui/icons/Speaker';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import HomeIcon from '@material-ui/icons/Home';
import PlaceIcon from '@material-ui/icons/Place';
import DnsIcon from '@material-ui/icons/Dns';
import AppsIcon from '@material-ui/icons/Apps';
import BusinessTwoToneIcon from '@material-ui/icons/BusinessTwoTone';
import HomeWorkTwoToneIcon from '@material-ui/icons/HomeWorkTwoTone';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import PlaceTwoToneIcon from '@material-ui/icons/PlaceTwoTone';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { FaMicrosoft, FaGoogle } from 'react-icons/fa';
import { GrServices } from 'react-icons/gr';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import PersonalVideoIcon from '@material-ui/icons/PersonalVideo';
import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputCompositeTwoTone';
export const configurableIcon = (configId: string, props?: any) => {
  switch(configId) {
    case 'Phone': return <SmartPhoneIcon/>
    case 'Laptop': return <LaptopIcon/>
    case 'KeyboardAndMouse': return <KeyboardIcon/>
    case 'RemoteMeetingPackage': return <VideocamIcon/>
    case 'Display': return <DesktopWindowsIcon/>
    case 'RemoteAssistance': return <LiveHelpOutlinedIcon/>
    case 'Email': return <MailOutlineIcon/>
    case 'EmployeeDevices': return <PersonOutlineTwoToneIcon {...props} />
    case 'ConstructionSite': return <PlaceTwoToneIcon {...props} />
    case 'Service': return <SettingsInputCompositeIcon {...props} />
    case 'Printer': return <PrintIcon {...props} />
    case 'DnsManagement': return <DnsIcon {...props} />
    case 'LaptopAccessories': return <WorkIcon {...props} />
    case 'DesktopComputer': return <SpeakerIcon {...props} />
    case 'BusinessApps': return <AppsIcon {...props} />
    case 'Security': return <SecurityIcon {...props} />
    case 'BackupService': return <BackupOutlinedIcon {...props} />
    case 'Hosting': return <HomeOutlinedIcon {...props} />
    case 'CentralizedManagement': return <SupervisorAccountIcon {...props} />
    case 'GoogleWorkspace': return <FaGoogle {...props} />
    case 'Microsoft365': return <FaMicrosoft {...props} />
    case 'Networking': return <NetworkCheckIcon {...props} />
    case 'InfoDisplay':return <PersonalVideoIcon {...props} />
    case 'VideoConferencingSystem': return <VideoCallIcon {...props} />
    case 'OfficeDevice': return <HomeWorkTwoToneIcon {...props} />
    default: return <BlockIcon  {...props}/>
  }
}