import Configurable from './Configurable';
import {ResourceState} from './types'; 
import FunctionVariable from './FunctionVariable';
import flex from "./../";

class Resource extends Configurable {
  private _parentId: string | null = null;
  constructor(params: ResourceState, save: () => void) {
    super(params, save);
    if(params._parentId) this._parentId = params._parentId;
  }
  getParentId(): string | null {
    return this._parentId;
  }
  getParent(): Configurable | null {
    if (this._parentId) return flex.getEnabledConfigurable("ResourceGroup", this._parentId)
    else return null;
  }
  
  public calculatePrice(): number {
    let price = this.getConfig("Price");
    if (!price) return 0;
    else return Math.ceil(price * 100) / 100;
  } 
}
export default Resource;