import flex, {ResourceGroup} from '../../flex';
import React, {useEffect, useState} from 'react';
import ResourceView from './ResourceView';
import OptionConfigurations from './OptionConfigurations';
import {DefaultButton as Button, SecondaryButton} from './../Buttons';
import {configurableIcon} from '../icons';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import {PriceTag} from './../Buttons/styled';
import ControlPointIcon from '@material-ui/icons/ControlPoint';


export default (props: {
  action: 'create' | 'edit',
  uniqueId?: string,
  configId?: string,
  closeHandler?: () => void
}) => {

  const [action, setAction] = useState<'create' | 'edit'>(props.action);
  const [resourceGroup, setResourceGroup] = useState<ResourceGroup | null>()
  const [enabledResources, setEnabledResources] = useState<string[]>([]); // used as a state to trigger re-render


  useEffect(() => {
    const reload = (): void => {
      let rg;
      if (action === 'create' && props.configId) rg = flex.createResourceGroup(props.configId);
      else if  (action === 'edit' && props.uniqueId) {
        rg = flex.getEnabledConfigurable('ResourceGroup', props.uniqueId);
        setEnabledResources( (rg as ResourceGroup).listEnabledResources().map( r => r.getUniqueId() ));
      }
      if (!rg) throw new Error('You need to pass either configId or uniqueId');
      console.log('setting', rg)
      console.log(action);
      setResourceGroup(rg as ResourceGroup);
    }

    flex.ready().then(reload)

    flex.subscribe(flex.events.ResourceDisabled, reload);
    if (action === 'edit') {
      flex.subscribe(flex.events.ResourceGroupEnabled, reload);
    }
    return () => {
      flex.unSubscribe(flex.events.ResourceDisabled, reload);
      if (action === 'edit') {
        flex.unSubscribe(flex.events.ResourceGroupEnabled, reload);
      }
    }
  }, [])


  if (!resourceGroup) return <h2>Loading...</h2>;

  else if (action === 'create') {
    return (
      <>
        <h3>Lisää {resourceGroup.getConfig('Name')}</h3>

        <OptionConfigurations configurable={resourceGroup} autoSave={false} />

        <Button style={{marginTop:"20px"}} variant="contained" color="primary"
          onClick={() => {
            try {
              resourceGroup.save();
              setAction('edit');
            }
            catch(e) { 
              flex.publish(flex.events.Notification, {
                severity: "error",
                message: e.message
              }); 
            }
          }}
        >
          Tallenna
        </Button>

      </>
    )
  }
  else {
    return (
      <>
        <SecondaryButton 
          style={{float:"right"}}
          variant="outlined"
          onClick={ () => {
            flex.publish(flex.events.Dialog, {
              title: `Haluatko poistaa ${resourceGroup.getConfig('Name')}?`,
              confirmText: "Kyllä, poista",
              cancelText: "Peruuta",
              confirm: () => {
                flex.disableConfigurable('ResourceGroup', resourceGroup)
                if (props.closeHandler) props.closeHandler();
              },
              cancel : () => {
                // nothing to do
              }
            })


          }}
          startIcon={<DeleteIcon/>}
          >Poista</SecondaryButton>

        <Grid container
          alignItems="center"
          direction="row"
          justify="space-between"
          wrap="nowrap"
        
        >
          <Grid item>
            <h3>{resourceGroup.getConfig('Name')}</h3>
          </Grid>
          <Grid item>
            <PriceTag>
              {resourceGroup.calculatePrice()} €
            </PriceTag>
          </Grid>
        </Grid>
        
      
        <OptionConfigurations configurable={resourceGroup} />

          {resourceGroup.listEnabledResources().length > 0 && (
            <>
              <h4>Valitut palvelut</h4>
              <div style={{
                padding:"10px",
                //border: "1px solid #343A40",
                boxShadow:"0px 1px 3px #777",
                borderRadius:"6px",
                background:"#fff",
              }}>
                {resourceGroup.listEnabledResources().map((resource) => {
                  return (
                    <Chip
                      style={{
                        margin:"6px 6px 6px 0",
                        padding:"8px",
                        fontSize:"110%"
                      }}
                      size="medium"
                      icon={configurableIcon(resource.getConfig('_configId'))}
                      label={resource.getConfig('Name')}
                      onClick={() => {
                        flex.publish(flex.events.OpenSlideModal, {
                          component: <ResourceView action="edit" resourceGroup={resourceGroup} resource={resource} />
                        });
                      }}
                      onDelete={() => {
                        flex.disableConfigurable('Resource', resource);
                      }}
                    />
                  )
                })}
              </div>
            </>
          )}

          <Button 
              style={{
                marginTop:"20px"
            }} 
              variant="contained" 
              onClick={()=>{
                flex.publish(flex.events.OpenSlideModal, {
                  component: <ResourceView action="select" resourceGroup={resourceGroup} />
                });
              }}
              startIcon={<ControlPointIcon />}
            >
              Lisää { (resourceGroup.listEnabledResources().length === 0) ? 'ensimmäinen' : 'uusi'} laite tai palvelu
          </Button>
     


      </>
    )
  }


}