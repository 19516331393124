import styled from 'styled-components';

import Checkbox from '@material-ui/core/Checkbox';

export const GreenCheckbox = styled(Checkbox)`
    svg{
      color: green;
      font-size:24x;
    }
`;
