import Configurable from './Configurable';
import { ConfigurableConfig, ResourceGroupState} from './types';
import Resource from './Resource';
import flex from '..';




class ResourceGroup extends Configurable {
  public listAvailableResources: () => ConfigurableConfig[];
  public listEnabledResources: () => Resource[];
  constructor(
    params: ResourceGroupState, 
    save: any, 
    listAvailable: () => ConfigurableConfig[], 
    listEnabled: () => Resource[],
    add: (r: Resource) => void
  ) {
    super(params, save);
    this.listAvailableResources = listAvailable;
    this.listEnabledResources = listEnabled;
  }
  public calculatePrice(): number {

    let resources = flex.listEnabledConfigurables("Resource", {_parentId: this.getUniqueId()});
    let price = 0;
    for (var resource of resources) {
      price = price + resource.calculatePrice()
    }
    return Math.ceil( price * 100) / 100;
  }
}
export default ResourceGroup;