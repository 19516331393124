//import { createBrowserHistory } from 'history';
// const history = createBrowserHistory()

// const schema = require('./events-schema');
// const validator = new (require('jsonschema')).Validator();
// for (var key in schema) validator.addSchema(schema[key], key);


class Events {
  private _events: any
  constructor(){
    this._events = {};
  }

  public subscribe(event: string, listener: (data: any) => void): void {
    if (!Array.isArray(this._events[event])) {
      this._events[event] = []
    }
    this._events[event].push(listener)
  }

  public subscribeOnce(event: string, listener: (data: any) => void): void {
    let self = this;
    self.subscribe(event, function g (data) {
      self.unSubscribe(event, g)
      listener.apply(self, [data])
    })
  }

  public unSubscribe(event: string, listener: (data: any) => void) {
    var idx
    if (Array.isArray(this._events[event])) {
      idx = this._events[event].indexOf(listener)
      if (idx > -1) {
        this._events[event].splice(idx, 1)
      }
    }
  }

  public publish(event: string, data: object) {
    console.log('EVENT: ' + event, data)
    
    // validator.validate(arguments, "/EventArguments", {throwError: true})

    var i, listeners, length;
    if (typeof this._events[event] === 'object') {
      listeners = this._events[event].slice()
      length = listeners.length
      for (i = 0; i < length; i++) {
        listeners[i].apply(this, [data])
      }
    }
  }
}

export default Events;

