import flex, {ResourceGroup, Resource} from '../../flex'
import {
          OptionConfigType, 
          ChoiceOptionConfig, 
          TextOptionConfig, 
          TextArrayOptionConfig, 
          RangeOptionConfig, 
          BooleanOptionConfig,
          NumberOptionConfig
      } from '../../flex/src/types';
import React, {useState} from 'react';


import RangeSlider from './RangeSlider';
import NumberField from './NumberField';
import Select from './Select';
import {GreenCheckbox} from './Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ChipInput from 'material-ui-chip-input'
import FunctionVariable from '../../flex/src/FunctionVariable';
 

type Configurable = ResourceGroup | Resource 

export default (props: {
  configurable: Configurable,
  autoSave?: boolean
}) => {

  const [errors, setErrors] = useState<{[key: string]: string}>({});

  const setChange = (configId: string, value: any) => {
    try { 
      props.configurable.setOption(configId, value, (typeof props.autoSave === 'boolean') ? props.autoSave : true ); 
      let newErrors = Object.assign({},errors);
      delete newErrors[configId]
      setErrors(newErrors);
    }
    catch(err){ 
      let newErrors = Object.assign({},errors);
      newErrors[configId] = err.message;
      setErrors(newErrors);
      flex.publish(flex.events.Notification, { message: err.message, severity: "error"})
    }
  }

  const renderText = (c: Configurable, option: TextOptionConfig) => {
    return (
      <TextField variant="filled" style={{width:"100%"}}
        key={option._configId}
        defaultValue={(c.getEnabledOptionValue(option._configId)) ? c.getEnabledOptionValue(option._configId) : ''}
        label={option.Name}
        error={errors[option._configId] ? true : false} 
        helperText={errors[option._configId] ? errors[option._configId] : ''} 
        onChange={(e: any) => {
          setChange(option._configId, e.target.value)
        }}
      />
    )
  }
  const renderChoice = (c: Configurable, option: ChoiceOptionConfig) => {

    return  (
      <Select key={option._configId}
        disabled={c.getOptionConfig(option._configId, 'Disabled') as boolean}
        title={c.getOptionConfig(option._configId, 'Name')}
        options={c.getOptionConfig(option._configId, 'Values')}
        defaultValue={(c.getEnabledOptionValue(option._configId)) ? c.getEnabledOptionValue(option._configId) : option.Default}
        onChange={(e: any) => {       
          setChange(option._configId, e.target.value)
        }}  
      >
      </Select>
    )
  }
  const renderNumber = (c: Configurable, option: NumberOptionConfig) => {
    return (
      <NumberField key={option._configId} title={option.Name}
        defaultValue={(c.getEnabledOptionValue(option._configId)) ? c.getEnabledOptionValue(option._configId) : option.Default}
        step={1}
        min={option.Min || 1}
        max={option.Max || 100}
        error={errors[option._configId] ? errors[option._configId] : ''} 
        onChange={(ev: any, newVal: number) => {
          setChange(option._configId, newVal)
        }}
      />
    )
  }
  const renderRange = (c: Configurable, option: RangeOptionConfig) => {
    return (
      <RangeSlider key={option._configId}
        title={option.Name}
        defaultValue={(c.getEnabledOptionValue(option._configId)) ? c.getEnabledOptionValue(option._configId) : option.Default}
        step={option.RangeStep || 1}
        min={option.Min || 1}
        max={option.Max || 100}
        onChangeCommitted={(ev: any, newVal: number) => {
          setChange(option._configId, newVal)
        }}
      /> 
    )
  }


  const renderCheckbox = (c: Configurable, option: BooleanOptionConfig) => {
    return (

      <FormControlLabel
        control={<GreenCheckbox disabled={c.getOptionConfig(option._configId, 'Disabled') as boolean} checked={(c.getEnabledOptionValue(option._configId) !== null) ? c.getEnabledOptionValue(option._configId) : option.Default} 
        onChange={(event: any, checked: boolean) => {
          console.log(checked);
          setChange(option._configId, checked)
        }} name="checkedG" />}
        label={option.Name}
      />
    )
  }
  const renderTextArray = (c: Configurable, option: TextArrayOptionConfig) => { 
    return (
        // uncontrolled input
        <ChipInput
        fullWidth
        fullWidthInput
        error={errors[option._configId] ? true : false} 
        helperText={errors[option._configId] ? errors[option._configId] : ''} 
        label={option.Name}
        variant="filled"
        defaultValue={(c.getEnabledOptionValue(option._configId)) ? c.getEnabledOptionValue(option._configId) : option.Default}
        onChange={(chips: any) => {
          setChange(option._configId, chips)
        }}
      />
    )
  }
    
  const renderOption = (c: Configurable, option: OptionConfigType) => {
    switch(option.OptionType){
      case 'Text': return renderText(c, option);
      case 'Choice': return renderChoice(c, option);
      case 'Range': return renderRange(c, option);
      case 'Boolean': return renderCheckbox(c, option);
      case 'Number': return renderNumber(c, option);
      case 'TextArray': return renderTextArray(c, option);
      default: return ( <div>Jani</div> );
    }
  }

  return (

    <div>
      {props.configurable.listAvailableOptions().map((option) => { 
        return (
          <div>
            {renderOption(props.configurable, option)}
            
            {option.Description && (
              <div style={{padding:"15px 10px 20px 10px", fontSize:"90%", border:"1px solid #ddd",background:"#fff"}} dangerouslySetInnerHTML={{__html: props.configurable.getOptionConfig(option._configId, "Description")}} />
            )}
            
          </div>
        )
      }


      )}
    </div>
  )
}