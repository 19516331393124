import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import TextField from '@material-ui/core/TextField';

import {DefaultButton, YellowButton} from './../Buttons/styled'
import flex from '../../flex'
import {ResourceGroup} from '../../flex'
import ThumbUpTwoToneIcon from '@material-ui/icons/ThumbUpTwoTone';
import CircularProgress from '@material-ui/core/CircularProgress';

const encode: any = (data: any) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}


export default () => {

  const [contactName, setContactName] = useState("")
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyVatId, setCompanyVatId] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");

  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);


  const handleSubmit = () => {
    setFormIsSubmitting(true);
    let data = {
      contactName : contactName,
      contactEmail : contactEmail,
      contactPhone : contactPhone,
      companyName : companyName,
      companyVatId : companyVatId,
      additionalInfo : additionalInfo,
      data : flex.toOfferJson()
    };
    setTimeout(() => {
      fetch('/', {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode(Object.assign({'form-name': 'slim-contact-form'}, data))
      })
      .then(() => {
        console.log('Form successfully submitted')
        setFormIsSubmitting(false);
        setFormIsSubmitted(true);
        flex.publish(flex.events.LogEvent, { event: "FormSubmitted" })
        
      })
      .catch((error) => {
        setFormIsSubmitting(false);
        alert(error)
        flex.publish(flex.events.LogEvent, {
          event: "FormSubmitError",
          errorDetail : error.message
        })
      });
    },0)


   
  }
  if (formIsSubmitting) {
    return (
      <Container style={{height:"80%"}}>
        <Grid
          container
          justify='center'
          alignItems='center'
          style={{height:'100%'}}
        >
            <CircularProgress size={62}/>
          </Grid>      
      </Container>
    )

  }
  else if (formIsSubmitted) {
    return (
      <Container>
          <Grid container direction="column" alignItems="center" justify="center" style={{textAlign:"center"}}>
  
            <Grid item>
  
               <h2>Kiitos!</h2>
               
               <ThumbUpTwoToneIcon style={{fontSize:"56px"}} />

                <p>Palataan asiaan pian.</p>
            </Grid>
          </Grid>
      </Container>
    )

  }
  else {
    return (
      <Container>
          <Grid container direction="column">
  
            <Grid item>
  
               <h2>Jätä tarjouspyyntö</h2>
               <p>Jätä tarjouspyyntö niin lähetämme sinulle vielä tarkan hinnan. Voit myös antaa lisätietoa tarpeistasi.</p>
               <p>Tampereen Datapiste Oy on vuonna 2007 perustettu suomalainen ja luotettava IT-alan palvelutoimittaja. Antamiasi tietoja käytetään vain tarjouspyynnön käsittelyyn.</p>
  
            </Grid>
  
            <Grid item>
              <Grid container direction="row" justify="space-between" spacing={2}>
  
                <Grid item xs={12} sm={6}>
                  <strong style={{display:"block",margin:"10px 0px",color:"#777"}}>Yhteyshenkilön tiedot</strong>
                  <TextField fullWidth variant="filled" onChange={ (e: any) => setContactName((e.target as HTMLInputElement).value)} label="Yhteyshenkilön nimi" />
                  <TextField fullWidth variant="filled" onChange={ (e: any) => setContactEmail((e.target as HTMLInputElement).value)} label="Yhteyshenkilön sähköposti" />
                  <TextField fullWidth variant="filled" onChange={ (e: any) => setContactPhone((e.target as HTMLInputElement).value)} label="Yhteyshenkilön puhelinnumero" />
  
                </Grid>
                <Grid item xs={12} sm={6}>
  
                  <strong style={{display:"block",margin:"10px 0px",color:"#777"}}>Yrityksen tiedot</strong>
                  <TextField fullWidth variant="filled" onChange={(e: any) => setCompanyName((e.target as HTMLInputElement).value)} label="Yrityksen nimi" />
                  <TextField fullWidth variant="filled"  onChange={(e: any) => setCompanyVatId((e.target as HTMLInputElement).value)} label="Y-Tunnus" />
                  <TextField fullWidth variant="filled" 
                      onChange={(e: any) => setAdditionalInfo((e.target as HTMLInputElement).value)}
                      id="outlined-multiline-static"
                      label="Lisätiedot (ei pakollinen)"
                      multiline
                      rowsMax={10}
                    />
                </Grid>
              </Grid>
  
        
  
              <Grid item>
  
                <YellowButton onClick={() => {
                  handleSubmit();
                }} size="large" style={{marginTop:"20px"}}>
                  Lähetä tarjouspyyntö
                </YellowButton>
                <div style={{fontSize:"85%"}}>
                  
  
    
                </div>
                
  
              </Grid>
            </Grid>
          </Grid>
   
      </Container>
  
    )
  }

}