import React, {useState, useEffect} from 'react';
import flex, {ResourceGroup} from '../../flex';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { ConfigurableConfig } from '../../flex/src/types';
import {configurableIcon} from '../icons'
import {ResourceGroupView, ResourceView} from '../ConfigurableView';
import Chip from '@material-ui/core/Chip';
import Zoom from '@material-ui/core/Zoom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import {ResourceGroupDisplayNameLink} from './styled';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CircularProgress from '@material-ui/core/CircularProgress';

import {DefaultButton, PriceTag} from './../Buttons/styled';

export default () => {
  const [availableResourceGroups, setAvailableResourceGroups] = useState<ConfigurableConfig[]>([])
  const [enabledResourceGroups, setEnabledResourceGroups] = useState<ResourceGroup[]>([])
  const [ready, setReady] = useState(false)

  const reload = () => {
    setAvailableResourceGroups([...flex.listAvailableConfigurables("ResourceGroup")]);
    setEnabledResourceGroups([...flex.listEnabledConfigurables("ResourceGroup") as ResourceGroup[]]);   
    setReady(true)
  }

  useEffect(() => {
    flex.ready().then(reload)
    flex.subscribe(flex.events.ResourceGroupEnabled, reload);
    flex.subscribe(flex.events.ResourceGroupDisabled, reload);
    flex.subscribe(flex.events.ResourceEnabled, reload);
    flex.subscribe(flex.events.ResourceDisabled, reload);

    window.onbeforeunload = () => {
      return "Olet poistumassa sivulta. Asetukset eivät välttämättä tallennu.";
    }


    return () => {
      flex.unSubscribe(flex.events.ResourceGroupEnabled, reload);
      flex.unSubscribe(flex.events.ResourceGroupDisabled, reload);
      flex.unSubscribe(flex.events.ResourceEnabled, reload);
      flex.unSubscribe(flex.events.ResourceDisabled, reload);
    }
  }, [flex])

  const renderEnabledResourceGroup = (rg: ResourceGroup) => {
    return (
      <Grid 
        container 
        spacing={2} 
        key={rg.getUniqueId()}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{
          border:"1px solid #ccc",
          padding:"20px",
          marginBottom:"20px",
          background:'#eee',
          color:"#222",
          borderRadius:'10px',
        }}
      >


        <Grid item xs={2} md={1}>
       
          {configurableIcon(rg.getConfig('_configId'), {
            style: { fontSize: 40 },
            color:"action"
          })}

        </Grid>

        <Grid item xs={10} md={9} >

          <Grid 
            container 
            spacing={2} 
            direction="column"
          >

            <Grid item 
              onClick={() => {
                flex.publish(flex.events.OpenSlideModal, {
                  component: <ResourceGroupView action="edit" uniqueId={rg.getUniqueId()} />
                });
                flex.publish(flex.events.LogEvent, { event: "EditResourceGroup" });
              }} 
            >
              <ResourceGroupDisplayNameLink>
                <EditOutlinedIcon style={{float:"left",marginRight:"10px"}} fontSize="small"/> {rg.getDisplayName()} 
              </ResourceGroupDisplayNameLink>

            </Grid>

            <Grid item>
              {rg.listEnabledResources().map( (resource) => {
                return (
                  <Zoom in={true}>
                    <Chip
                      style={{
                        margin:"3px"
                      }}
                      icon={configurableIcon(resource.getConfig('_configId'), {size:24})}
                      label={resource.getConfig('Name')}
                      // color="primary"
                      //color="info"
                      onClick={() => {
                        flex.publish(flex.events.OpenSlideModal, {
                          component: <ResourceView action="edit" resourceGroup={rg} resource={resource} />
                        });
                      }}
                      onDelete={() => {
                        flex.publish(flex.events.Dialog, {
                          title: `Haluatko poistaa ${resource.getConfig('Name')}?`,
                          //content: "heipat",
                          confirmText: "Kyllä, poista",
                          cancelText: "Peruuta",
                          confirm: () => {
                            flex.disableConfigurable('Resource', resource);
                          },
                          cancel : () => {
                            // nothing to do
                          }
                        })
                        
                      }}
                    />
                  </Zoom>
                )
              })}
   
                <Zoom in={true}>
                  <Chip
                    style={{
                      margin:"3px",
                      background:"#007BFF",
                      color:"#fff"
                    }}
                    icon={<ControlPointIcon style={{color:"#fff"}} />}
                    label={rg.listEnabledResources().length === 0 ? 'Lisää ensimmäinen' : 'Lisää uusi'}
                    onClick={() => {
                      if (rg.listEnabledResources().length === 0) {
                        flex.publish(flex.events.OpenSlideModal, {
                          component: <ResourceGroupView action="edit" uniqueId={rg.getUniqueId()} />
                        });
    
                      }
                      else {
                        flex.publish(flex.events.OpenSlideModal, {
                          component: <ResourceView action="select" resourceGroup={rg} />
                        });
  
                      }

                    }}
          
                  />
                </Zoom>
            

            </Grid>
            
        </Grid>


      </Grid>
      
      <Grid item>
        {rg.calculatePrice() > 0 && (
          <PriceTag style={{marginLeft:"10px"}}>
            {rg.calculatePrice()} EUR
          </PriceTag>
        )}

      </Grid>  

    </Grid>

    )
  }

  if (!ready) {
    return (
      <Container style={{paddingTop: "20vh"}}>
        <Grid
          container
          
          justify='center'
          alignItems='center'
          direction="column"
          style={{height:'100%'}}
        >
          <Grid item>
            <CircularProgress size={48}/>
          </Grid>
          <Grid item>
              <small>Ladataan...</small>
          </Grid>
        </Grid>
      </Container>

    )
  }
  else {
    return (
      <Container style={{paddingBottom:"10vh"}}>

        {availableResourceGroups.map( (rg, index) => {
          let activeEnabledResourceGroups = enabledResourceGroups.length === 0 ? [] : enabledResourceGroups.filter( (enabledRg) => { 
            return enabledRg.getConfig('_configId') === rg._configId 
          });

          return (
            <>
                <Grid
                  container
                  direction={ (activeEnabledResourceGroups.length > 0) ? 'row' : 'column'}
                  justify={ (activeEnabledResourceGroups.length > 0) ? 'space-between' : 'space-between'}
                  alignItems={ (activeEnabledResourceGroups.length > 0) ? 'center' : 'flex-start'}
                  style={{
                    marginBottom:  (activeEnabledResourceGroups.length > 0) ? '0px' : '40px',
                  }}
                  
                >
                  <Grid item>
                    <h3>{rg.GroupTitle}</h3>
                  </Grid>

                  { flex.configurableLimitReached("ResourceGroup", rg._configId, null) === false && (

                    <Grid item>
                      <DefaultButton onClick={() => { 
                        flex.publish(flex.events.OpenSlideModal, {
                          component: <ResourceGroupView action="create" configId={rg._configId} />
                        });
                      }}
                      size={activeEnabledResourceGroups.length > 0 ? "small" : "large"} 
                      color="primary" 
                      variant={activeEnabledResourceGroups.length > 0 ? "text" : "contained"} 
                      startIcon={<ControlPointIcon />}>
                        Lisää uusi {rg.Name}
                      </DefaultButton>   
                    </Grid>
                  )}


                </Grid>
               
                {activeEnabledResourceGroups.map(renderEnabledResourceGroup)} 
   

            </>

          )
        })}

      </Container>
    )


  }

}
