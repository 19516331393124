import React, {useState, useEffect} from 'react';


import flex, {createUniqueId} from '../../flex';

import {OverlayBackground, SlideFrom, CloseIconBack, CloseIconDown, CloseIconForward, CloseIconUp, CloseIconX} from './styled';
import {isMobile} from 'react-device-detect';


type props = {
  containerHeight?: string
}

export default ({
  containerHeight = '100%'
}: props) => {

  useEffect(() => {
    flex.subscribe(flex.events.OpenSlideModal, openSlideModal);
    return () => { 
      flex.unSubscribe(flex.events.OpenSlideModal, openSlideModal); 
    }
  })

  type ElementType = {
    key: string, 
    width: number,
    openFrom: 'top' | 'left' | 'bottom' | 'right',
    component: React.ReactElement, 
    closeHandler?: () => void,
    willUnmount?: boolean
  };
  const [elements, setElements] = useState<ElementType[]>([])

  enum AnimationStages {
    Opening = "OPENING",
    Closing = "CLOSING",
    Closed = "CLOSED",
  }
  const [animationStage, setAnimationStage] = useState<AnimationStages | null>(null)

  const openSlideModal = (data: {
    component: React.ReactElement, 
    width?: number, 
    openFrom?: 'top' | 'left' | 'bottom' | 'right',
    closeHandler?: () => void,
  }) => {
    let newElement = {
      key : createUniqueId(),
      width: 50,  // set default width
      openFrom: 'right',
      ...data
    };

    if ( newElement.openFrom === 'left' || newElement.openFrom === 'right') { 
      if (window && window.innerWidth < 500 && window.innerWidth > 360) {
        newElement.width = Math.min(100, newElement.width * 1.75);
        
      }
      if (window && window.innerWidth <= 360) {
        newElement.width = Math.min(100, newElement.width * 2);
      }
    }
    
    setElements(elements.concat(newElement as ElementType))
    setAnimationStage(AnimationStages.Opening)
  }

  const closeSlideModal = (key: string) => {
    let newElements = elements.map( (element) => {
      if (element.key === key) return Object.assign(element, { willUnmount : true });
      else return element;
    });
    setElements(newElements)
    setAnimationStage(AnimationStages.Closing)
  }
  const unMountSlideModal = (key: string) => {
    let newElements = elements.filter( (element) => {
      if (element.key === key) {
        if (element.closeHandler) element.closeHandler();
        return false;
      }
      else return true;
    });
    setElements(newElements)
    setAnimationStage(AnimationStages.Closed)  
  }



  return (
    <div style={{
      color:"#222"
    }}>

      {elements.map( (data, index) => {

        let animateSlide = (animationStage === AnimationStages.Closed) ? false : true;
        let openedFrom = elements[elements.length - 1].openFrom;
        if ( data.openFrom !== openedFrom) animateSlide = false;
        let reverseSlide = (animationStage === AnimationStages.Closing && openedFrom === data.openFrom) ? true : false;
        let endPosition = 0;
        let startPosition = ((elements[index + 1]) ? elements[index + 1].width : data.width) * -1;
        for(var i = index + 1; i < elements.length; i++) {
          if (elements[i].openFrom === data.openFrom) {
            endPosition = endPosition + elements[i].width;
            startPosition = startPosition + elements[i].width;
          }
          else {
            animateSlide = false;
            reverseSlide = false;
            break;
          }
        }
      
       
        let animateBG = (index === elements.length - 1 && animationStage !== AnimationStages.Closed) ? true : false;
        let reverseBG = (index === elements.length - 1 && animationStage === AnimationStages.Closing) ? true : false;

        (window as any).elements = elements;

        return (
          <div key={data.key}>
            <OverlayBackground 
              containerHeight={containerHeight || '100%'}
              onClick={()=>{
                closeSlideModal(data.key);
              }}
              animate={animateBG}       
              reverse={reverseBG}  
              key={data.key}
            >
            </OverlayBackground>

            <SlideFrom
              containerHeight={containerHeight || '100%'}
              from={data.openFrom}
              start={startPosition}
              end={endPosition}
              width={Number(data.width)}
              animate={animateSlide} 
              reverse={reverseSlide} 
              onAnimationEnd={() => {
                if (animationStage === AnimationStages.Closing && data.willUnmount) {
                  console.log('unmounting slide')  
                  unMountSlideModal(data.key);         
                }
              }}
            >

              {(() => {
                switch(data.openFrom) {
                  case 'left': return <CloseIconX onClick={() => { closeSlideModal(data.key) }}/>;
                  case 'right': return <CloseIconX onClick={() => { closeSlideModal(data.key) }}/>;
                  case 'top': return <CloseIconX onClick={() => { closeSlideModal(data.key) }}/>;
                  case 'bottom': return <CloseIconX onClick={() => { closeSlideModal(data.key) }}/>;
                  default: return <CloseIconX onClick={() => { closeSlideModal(data.key) }}/>;
                }
              })()}
              

              {React.cloneElement(data.component, {slideModalKey: data.key, closeHandler: () => {
                closeSlideModal(data.key);
              }})}

            </SlideFrom>
          
          </div>
        )


      })}


    </div>
  )
}