import flex from "./../";
import {ConfigError} from "./Errors";
import {OptionRule} from './RuleProcessors'; 
import {ConfigurableConfig, 
  OptionConfigType, 
  ChoiceOptionConfig, 
  RangeOptionConfig, 
  BooleanOptionConfig, 
  TextOptionConfig, 
  NumberOptionConfig, 
  ResourceGroupState, 
  ResourceState,
  FunctionVariableType} from './types'; 

import FunctionVariable from './FunctionVariable';
import OptionConfigurations from "../../components/ConfigurableView/OptionConfigurations";

type ConfigurableState = ResourceGroupState | ResourceState

abstract class Configurable {
  protected config: ConfigurableConfig;
  protected _uniqueId: string;
  protected options: { _configId: string,  value: any }[] = [];
  public save: () => void;

  constructor(state: ConfigurableState, save: () => void ) {
    this.save = save;
    this._uniqueId = state._uniqueId;
    this.options = state.options;
    this.config = state.config;
  } 

  getFlex(): typeof flex {
    return flex
  };

  getUniqueId(): string {
    return this._uniqueId;
  }
  getConfig(key?: keyof ConfigurableConfig, optionConfigId?: string, optionConfigKey?: keyof OptionConfigType) {

    let config: ConfigurableConfig & {[key: string]: any} = this.config;

    if (!key) return config;
  
    if (typeof config[key] === 'object') {
      try {
        let func = new FunctionVariable(this, (config[key] as FunctionVariableType).Value);
        let result = func.evaluate();
        return result;
      }
      catch(e){
        console.log(config[key])
        return 0;
      }
    }
    else return config[key];

  }
  getOptionConfig(configId: string, key: keyof OptionConfigType | 'Values') {
    let option = this.config.Options.filter( (opt) => {
      return opt._configId === configId
    })[0];
    if (!option) throw new ConfigError(`No such option exists: ${this.getConfig('_configId')}.Options.${configId}`)

    // do we really need this?
    if (key === 'Values') {
      return (option as ChoiceOptionConfig).Values.map( (val) => {
        if (typeof val.Disabled === 'object') {
          let f = new FunctionVariable(this, val.Disabled.Value);
          return Object.assign({}, val, {Disabled: f.evaluate()})
        }
        else return val;
      });
    }
    else if (typeof option[key] === 'object') {
      let func = new FunctionVariable(this, (option[key] as FunctionVariableType).Value);
      let result = func.evaluate();
      return result;
    }
    else return option[key];
  }




  private getCurrentlyAllowedRange(rangeConfig: RangeOptionConfig | NumberOptionConfig) {
    return {Min: rangeConfig.Min, Max: rangeConfig.Max, MaxCurr: rangeConfig.Max, MinCurr: rangeConfig.Min}
  }
  private getCurrentlyDisallowedChoices(choiceConfig: ChoiceOptionConfig) {
    return [{_configId: "jani", disallow: true, disallowReason:"always disallow jani"}];
  }

  listAvailableOptions(): OptionConfigType[] {
    if (!this.config.Options) return [];
    else return this.config.Options;
    /*
    return this.config.Options.map( (opt) => {
      return this.getOptionConfig(opt._configId, 'Disabled');

      if (opt.OptionType === 'Choice') {
        opt.Values = opt.Values.map( (val) => {
          if (typeof val.Disabled === 'object') {
            let f = new FunctionVariable(this, val.Disabled.Value);
            val.Disabled = f.evaluate()
          }
          return val;
        })
      }
      return opt;
    });
    */

  }
  getAvailableOption(_configId: string): OptionConfigType {
    let config = this.config.Options.find(e => e._configId === _configId);
    if (config) return config;
    else {
      let msg = `Option ${_configId} does not exist in config`;
      let detail = `Available options: ${this.getConfig('Options').map( (e: any) => e._configId ).join(', ')}`;
      throw new ConfigError(msg, detail);
    }
  }

  enableOption(configId: string, valueConfigId?: string) {
    let optionConfig: OptionConfigType = this.getAvailableOption(configId);
    if (valueConfigId) {
      if (optionConfig.OptionType === 'Choice') {
        return optionConfig.Values.map( (val) => {
          if (val._configId === valueConfigId) val.Disabled = false;
          return val;
       });
      } 
    }
    else {
      optionConfig.Disabled = false;
    }
  }
  disableOption(configId: string, valueConfigId?: string) {
    let optionConfig: OptionConfigType = this.getAvailableOption(configId);
    if (valueConfigId) {
      if (optionConfig.OptionType === 'Choice') {
        return optionConfig.Values.map( (val) => {
          if (val._configId === valueConfigId) val.Disabled = true;
          return val;
       });
      } 
    }
    else {
      optionConfig.Disabled = true;
    }

  }


  /**
   * @Desc Set configurable option and value for resource
   */
  setOption(configId: string, value: any, autoSave = true) {
    let optionConfig: OptionConfigType = this.getAvailableOption(configId);

    if (optionConfig.OptionType === 'Text') {
      if (optionConfig.Pattern) {
        if (!value.match(new RegExp(optionConfig.Pattern))) {
          if (optionConfig.PatternFailText) throw new ConfigError(optionConfig.PatternFailText);
          else throw new ConfigError(`${configId} must match pattern ${optionConfig.Pattern}`, `Provided value ${value}`);
        }
      }
    }
    if (optionConfig.OptionType === 'TextArray') {
      if (optionConfig.Pattern) {
        for (var val of value) {
          if (!val.match(new RegExp(optionConfig.Pattern))) {
            if (optionConfig.PatternFailText) throw new ConfigError(optionConfig.PatternFailText);
            else throw new ConfigError(`${configId} must match pattern ${optionConfig.Pattern}`, `Provided value ${value}`);
          }
        }
      }
    }

    if (optionConfig.OptionType === 'Range' || optionConfig.OptionType === 'Number') {
      let range = this.getCurrentlyAllowedRange(optionConfig)
      let max = range.MaxCurr || range.Max;
      let min = range.MinCurr || range.Min;
      if ( (max && value > max) || (min && value < min)) {
        throw new ConfigError(`${configId} must be in range ${min}-${max}`, `Provided value ${value}`);
      }
    }
    if (optionConfig.OptionType === 'Choice') {

      let valueExists = optionConfig.Values.find( e => e._configId === value);
      if(!valueExists) {
        throw new ConfigError(`Value ${value} is not valid for ${configId}`, `Valid values are: ${optionConfig.Values.map( e => e._configId).join(', ')}`);
      }

      let disallowedValues = this.getCurrentlyDisallowedChoices(optionConfig);
      let valueConfig: {_configId: string, disallow:boolean, disallowReason:string} | undefined = disallowedValues.find(e => e._configId === value)
      if (valueConfig && valueConfig.disallow) {
        throw new ConfigError(`Value ${value} is not valid for ${configId}`, `Violation rule: ${valueConfig.disallowReason}`);
      }
      
    }
    /*
    this.emit('OPTION_UPDATED', {
      id : configId,
      oldValue : this.options[configId],
      newValue : value
    });
    console.log('setting new val ' + value)
    */
    let currentOptionIndex = this.options.map(e => e._configId).indexOf(configId);
    if (currentOptionIndex === -1) {
      this.options.push({
        _configId: optionConfig._configId,
        value: value
      })
    }
    else {
      this.options[currentOptionIndex] = {
        _configId: optionConfig._configId,
        value: value
      };
    }
    if(autoSave) this.save()
  }
  public listEnabledOptions() {
    return this.options.map( (option) => {
      let optionConfig = this.getAvailableOption(option._configId);
      return {Name: optionConfig.Name, ...option}
    })
  }
  public getEnabledOptionValue(_configId: string) {
    let option = this.options.find( e => e._configId === _configId);

    if (option) return option.value;
    else return null;
  }

  public getDisplayName(): string {
    let displayNameOption = (this.getConfig('DisplayNameOption')) ? this.getConfig('DisplayNameOption') : 'Name';
    let value = this.getEnabledOptionValue(displayNameOption)
    if (!value) value = this.getConfig('_configId') + '_' + this.getUniqueId()
    return value;

  }


}

export default Configurable;