import React, {useEffect} from 'react';
import Home from './components/Home';
import Header from './components/Header'
import SlideModalView from './components/SlideModalView';
import RequestQuoteView from './components/RequestQuoteView';
import NotificationView from './components/NotificationView';
import DialogView from './components/DialogView';
import DataLayer from './components/DataLayer';

import flex from './flex';

import styled from 'styled-components';
import {isMobileOnly} from 'react-device-detect';
const MainWrapper = styled.div`
  position:fixed;
  top:0px;
  left:0px;
  width:100%;
  height:90vh;
  overflow-y:auto;
  background:#343A40;
  color: #f3f3f3;
  font-family: verdana;
  line-height:160%;

`; 
const CtaWrapper = styled.div`
  height:10vh;
  width:100%;
  background:#ddd;
  position:fixed;
  bottom:0px;
  left:0px;
  border:1px solid #fff;
  box-shadow:0px -1px 2px #999; 
  min-height:50px;
  @media (max-width: 500px) {
    font-size:80%
  }
  @media (max-height: 500px) {
    font-size:80%
  }
`;



function App() {
  return (
    <div>

        <MainWrapper>
          {console.log(`NODE_ENV: ${process.env.NODE_ENV}`)}
          {console.log(`CUSTOM_ENV_VAR: ${process.env.CUSTOM_ENV_VAR}`)}
          {console.log(`REACT_APP_CUSTOM_ENV_VAR: ${process.env.TOML_ENV_VAR}`)}
          {console.log(`TOML_ENV_VAR: ${process.env.TOML_ENV_VAR}`)}
          {console.log(`REACT_APP_TOML_ENV_VAR: ${process.env.REACT_APP_TOML_ENV_VAR}`)}
          
          <Header />
        
          <Home />
  

          <SlideModalView containerHeight="90vh" />
  
        </MainWrapper>

        <CtaWrapper>
          <RequestQuoteView />
        </CtaWrapper>

        <DialogView/>

        <NotificationView/>

        <DataLayer />
        
    </div>
  )
}

export default App;