import {FlexConfig, ResourceGroupState, ResourceState, ConfigurableConfig, configurableTypes} from './types'; 
import repo from './Repo';
import ResourceGroup from './ResourceGroup';
import Resource from './Resource';
import createUniqueId from './uniqueId';
import Events from './Events';
import { ConfigError } from './Errors';
import FunctionVariable from './FunctionVariable';


type Configurable = ResourceGroup | Resource;


class Flex extends Events {
  private config: FlexConfig | null = null;
  private ResourceGroup: ResourceGroup[] = [];
  private Resource: Resource[] = [];

  // list of pub/sub events
  public events = {
    ResourceGroupEnabled: 'ResourceGroup_Enabled',
    ResourceEnabled: 'Resource_Enabled',
    ResourceGroupDisabled: "ResourceGroup_Disabled",
    ResourceDisabled: "Resource_Disabled",
    OpenSlideModal : "Open_SlideModal",
    CloseSlideModal : "Close_SlideModal",
    Notification: "Notification",
    Dialog: "Dialog",
    LogEvent: "LogEvent",
  }


  constructor() {
    super()
    if(!this.config) {
      repo.getFlexState().then( (state) => {
        

        state.config.ResourceGroup = state.config.ResourceGroup.map( (c) => {
          if (isNaN(Number(c.Max))) c.Max = 10;
          return c;
        })
        state.config.Resource = state.config.Resource.map( (c) => {
          if (isNaN(Number(c.Max))) c.Max = 1;
          return c;
        })

        this.config = state.config;

        this.ResourceGroup = state.ResourceGroup.map( (data) => {
          return this.loadResourceGroup(data)
        }) || [];

        this.Resource = state.Resource.map( (data) => {
          return this.loadResource( data)
        
        })  || [];

        this.config.ResourceGroup.forEach( (configurableConfig) => {
          if (configurableConfig.AutoCreate) {
            let configIdArray = this.ResourceGroup.map( (rg) => {
              return rg.getConfig('_configId')
            })
            if ( configIdArray.indexOf(configurableConfig._configId) === -1) {
              let rg =  this.createResourceGroup(configurableConfig._configId)
              this.enableConfigurable('ResourceGroup', rg)
            }
          }
        });

      })
    }
  }

  

  public async ready(): Promise<Flex> { 
    return await new Promise( (resolve) => {
      (window as any).flex = this;
      if(this.config) resolve(this);
      let interval = setInterval( () => {
        if(this.config) {
          clearInterval(interval); 
          resolve(this);
        }
      }, 200);
    });
  }

  private __getSaveFunction(type: configurableTypes) {
    let flex = this;
    return function(this: ResourceGroup | Resource) {  console.log('saving', this); flex.enableConfigurable(type, this); return this;  }
  }

  private loadResourceGroup(configurableState: ResourceGroupState) {
    return new ResourceGroup(
      configurableState, 
      this.__getSaveFunction("ResourceGroup"), 
      () => this.listAvailableConfigurables('Resource', {Group: configurableState.config._configId}),
      () => this.listEnabledConfigurables('Resource', {_parentId: configurableState._uniqueId}) as Resource[], 
      (resource: Resource) => {  return this.enableConfigurable('Resource', resource)}
    );
  }
  public createResourceGroup(configId: string) {
    let config = this.listAvailableConfigurables("ResourceGroup", {_configId: configId})[0];
    let params = {
      config: config, 
      _uniqueId: createUniqueId(),
      options: []
    }
    return new ResourceGroup(
      params, 
      this.__getSaveFunction('ResourceGroup'), 
      () => this.listAvailableConfigurables('Resource', {Group: config._configId}),
      () => this.listEnabledConfigurables('Resource', {_parentId: params._uniqueId}) as Resource[], 
      (resource: Resource) => {  return this.enableConfigurable('Resource', resource)}
    );
  }
  private loadResource(configurableState: ResourceState) {
    return new Resource(configurableState, this.__getSaveFunction("Resource"));
  }
  public createResource(configId: string, parentId: string) {
    let config = this.listAvailableConfigurables("Resource", {_configId: configId})[0];
    let params = {
      config: config, 
      _uniqueId: createUniqueId(),
      _parentId: parentId,
      options: [],
    }
    return new Resource(params, this.__getSaveFunction("Resource"))
  }

  private _getConfigurableIndex(type: configurableTypes, uniqueId: string): number {
    return (this[type] as Configurable[]).map( (e) => e.getUniqueId()).indexOf(uniqueId);
  } 

  public listAvailableConfigurables(type: configurableTypes
    , filter?: {[key:string]: any }): ConfigurableConfig[] {
    if (this.config === null) return [];
    let config: FlexConfig = this.config;
    
    if (!filter) return config[type];
    
    return config[type].filter( (configurable: ConfigurableConfig & {[key:string]: any }) => {
      
      let included: boolean = true;

      for(let filterKey of Object.keys(filter)) {
        if (!configurable[filterKey]) {
          included = false;
          break;
        }
        else if (typeof configurable[filterKey] === 'string') {
          if (configurable[filterKey] !== filter[filterKey]) {
            included = false;
            break;
          }
        }
        else if (Array.isArray(configurable[filterKey])) {
          if (configurable[filterKey].indexOf(filter[filterKey]) === -1) {
            included = false;
            break;
          }
        }
        else {
          throw new Error('Not prepared to handle anything else than strings and arrays in listEnabledConfigurables with filter')
        }
   
      }
      return included;
    });
  }

  public listEnabledConfigurables(type: configurableTypes, filter?: {[key:string]: any}): Configurable[] {
    if (!filter) return this[type]; 
    let configurables = (this[type] as Configurable[]).filter( (configurable: Configurable & {[key:string]: any}) => {
      let included: boolean = true;
      for(let filterKey of Object.keys(filter)) {
        if (!configurable[filterKey] || configurable[filterKey] !== filter[filterKey]) {
          included = false;
          break;
        }
      }
      return included;
    });
    if(type === 'ResourceGroup') return configurables as ResourceGroup[];
    else return configurables as Resource[];
  }

  public getEnabledConfigurable(type: configurableTypes, uniqueId: string): Configurable | null {
    let index = this._getConfigurableIndex(type, uniqueId);
    if (index === -1) return null;
    else return this[type][index];
  }

  public configurableLimitReached(type: configurableTypes, configId: string, parentId: string | null): boolean {
    let currentCount = 0;
    currentCount = this.listEnabledConfigurables(type).filter( (c) => {
      if (parentId) {
        return c.getConfig('_configId') === configId && 
        (c as Resource).getParentId() === parentId
      }
      else {
        return c.getConfig('_configId') === configId 
      }
      
    }).length

    let config = this.listAvailableConfigurables(type, {_configId: configId})[0];

    if (typeof config.Max === 'number' && currentCount + 1 > config.Max) {
      return true;
    }
    return false;
  }
  

  public enableConfigurable(type: configurableTypes, configurable: Configurable): void {

    let dependsOn = configurable.getConfig('DependsOn');
    if (typeof dependsOn !== 'undefined' && !dependsOn) {
      throw new ConfigError( configurable.getConfig('DependsOnDescription') || `Unable to save configurable because of dependencies.`)
    }
     

    let enabledOptions = configurable.listEnabledOptions().map( e => e._configId)
    configurable.listAvailableOptions().map( (ao) => {
      if (enabledOptions.indexOf(ao._configId) === -1) {
        if (typeof ao.Default !== 'undefined') {
          configurable.setOption(ao._configId, ao.Default, false);
        }
        else throw new ConfigError(`Unable to save Configurable. You need to specify ${ao.Name} (${ao._configId})`);
      }
    }) 

    
    let existingConfigurableIndex = this._getConfigurableIndex(type, configurable.getUniqueId())
    if (existingConfigurableIndex === -1) {

      let configId = configurable.getConfig('_configId');
      let parentId = (type === 'Resource') ? (configurable as Resource).getParentId() : null;
      
      if (this.configurableLimitReached(type, configId, parentId)) {
        throw new ConfigError(`Already reached maximum of ${configurable.getConfig('Max')} configurables for ${configurable.getConfig('Name')}`)
      }

      if (type === 'ResourceGroup') this[type].push(configurable as ResourceGroup);
      else this[type].push(configurable as Resource);
    }
    else this[type][existingConfigurableIndex] = configurable;


    let triggered = configurable.getConfig("Trigger");
    if (triggered) {
      this.publish(this.events.Notification, {message: triggered, severity:"info"})

    }
    repo.storeFlexState(this);
    if (type === "ResourceGroup") this.publish(this.events.ResourceGroupEnabled, configurable);
    else if (type === "Resource") this.publish(this.events.ResourceEnabled, configurable);
  }

  public disableConfigurable(type: configurableTypes, configurable: Configurable): void {
    let existingConfigurableIndex = this._getConfigurableIndex(type, configurable.getUniqueId())
    if (existingConfigurableIndex > -1) {
      this[type].splice(existingConfigurableIndex, 1);
      repo.storeFlexState(this);
      if (type === "ResourceGroup") this.publish(this.events.ResourceGroupDisabled, configurable);
      else if (type === "Resource") this.publish(this.events.ResourceDisabled, configurable);
    }

  }

  public calculatePrice() {
    let price = 0;
    let resourceGroups = this.listEnabledConfigurables("ResourceGroup");
    for (var rg of resourceGroups) {
      price = rg.calculatePrice() + price;
    }
    return Math.ceil( price * 100) / 100;
  }

  public toOfferSpecs() {
    let out: {[key: string]: any} = {};

    out['_configVersion'] = (this.config) ? this.config.Version : 'undefined';
    out['ResourceGroup'] = this.listEnabledConfigurables('ResourceGroup').map( (rg) => {
      return {
        _configId: rg.getConfig('_configId'),
        _uniqueId : rg.getUniqueId(),
        Name: rg.getConfig('Name'),
        options: rg.listEnabledOptions(),
        price: rg.calculatePrice(),
        resources: (rg as ResourceGroup).listEnabledResources().map( (r) => {
          return {
            _configId: r.getConfig('_configId'),
            options: r.listEnabledOptions(),
            price: r.calculatePrice(),
          }
        })
      };
    })
    out['_sumPrice'] = this.calculatePrice()
    return out;
  }
  public toOfferJson() {
    return JSON.stringify(this.toOfferSpecs())
  }
}
export default Flex;                    