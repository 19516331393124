import React from 'react';
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import {isMobile, MobileView} from 'react-device-detect';
import logo from '../../images/tdp-slim-logo.png'; // Tell webpack this JS file uses this image
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Button from '@material-ui/core/Button'
import flex from '../../flex';
import {LogoWrapper} from './styled';


export default () => {
  return (
    <Container>
    <Grid container
      style={{
        //background:"#fff",
        color:"#333",
        background:"transparent",
        padding:"26px 0px 16px 0px",
        textAlign: 'left',
      }}
      alignItems="center"
      justify="space-between"
      spacing={0}

    >
      


      <Grid item xs={4}>
        <LogoWrapper>
          <img alt="TDP Slim logo" style={{marginTop:"4px"}} src={logo} />
        </LogoWrapper>
      </Grid>
      <Grid item xs={8} style={{textAlign:"right"}}>
        <Button 
          onClick={() => {
            flex.publish(flex.events.OpenSlideModal, {
              component: (
                <div>
                  <h3>Yrityksesi IT-palveluiden kustannusarvio sekunnissa</h3>
                  <div style={{background:"#fff",padding:"20px"}}>


                    
                  <p>TDP Slim&trade; -palvelu on suunnattu yrittäjille, startup -yrityksille ja pienille asiantuntijayrityksille.</p>
                  <p>TDP Slim&trade;in avulla voitte
                    <ul>
                      <li>valita tarvitsemanne IT-palvelut</li>
                      <li>nähdä palveluiden kustannuksen kuukaudessa</li>
                      <li>jättää tarjouspyynnön valituista palveluista</li>
                    </ul>
                  </p>
                  <p>Palvelu Tampereen Datapiste Oy:n (<a href="https://www.tdp.fi">www.tdp.fi</a>) kehittämä. TDP on 
                    vuonna 2007 perustettu luotettava suomalainen IT-palvelutoimittaja, joka 
                    palvelee yritysasiakkaita kaikkialla Etelä-Suomessa.</p>
                  </div>

                
                </div>
              )
            })
        }}
        startIcon={<HelpOutlineIcon/>} style={{color:"#ddd"}}
        >Tietoa palvelusta</Button>
      </Grid>


      {process.env.NODE_ENV !== 'production' && (
        <div style={{
          position:"fixed",
          top:"0px",
          right:"0px",
          background:"red",
          color:"white",
          padding:"2px 2px"
        }}>
          ENV: {process.env.NODE_ENV}
        <button
          style={{marginLeft:"5px"}}
          onClick={() => {
            localStorage.removeItem('_flex');
            window.location.href = "/"
          }}
        >Reset
      </button>

        </div>
      )}


    </Grid>
    </Container>

  )
}