import Configurable from "./Configurable";
import flex from './../';

const Handlebars = require("handlebars");

export default class FunctionVariable {
  
  private caller: Configurable;
  private functionString: string;

  constructor(caller: Configurable, functionString: string) {

    flex.listEnabledConfigurables("ResourceGroup");
    this.caller = caller;
    this.functionString = functionString;

  }
  evaluate() {
    let func: any;
    try {
      func = new Function('return ' + this.functionString)();
      return func(this.caller);
    }
    catch(e){
      console.log(func);
      console.log(e);
      throw e;
    }
  }
}