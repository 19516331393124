import styled from 'styled-components';

export const LogoWrapper = styled.div`

@media (max-width: 500px) {
  > img {
    max-height:36px;
  }
}

`;