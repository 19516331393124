class ConfigError extends Error {
  message: string;
  detail?: string;
  constructor(message: string, detail?: string) {
    super(message);
    console.error(message, detail)
    this.message = message;
    this.name = "CONFIG_ERROR";
    if(detail) this.detail = detail;
  }
};

export { ConfigError };
