import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const ResourceGroupDisplayNameLink = styled.div`
  padding:5px 8px;
  border-style:solid;
  border-width:1px;
  border-bottom-width: 3px;
  border-right-width: 2px;
  border-radius:6px;
  border-color:#ccc;
  &:hover {
    cursor:pointer;
    border-color:blue;
  }
  &:hover svg {
    color:blue;
  }
`;

export const DefaultButton = styled(Button)`
  && {
    background-color: #007BFF;
    color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 7px 14px;
    &:hover {
      background-color: #5469d4;
    }

  }

`;