import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


export default (props: {
  key: string,
  onChange: (e: any) => void,
  defaultValue: string,
  title: string,
  options: {
    _configId: string,
    Name: string,
    Disabled?: boolean
  }[],
  children: never[]
  disabled?: boolean
}) => {
  return (
    <FormControl variant="filled" style={{width:"100%"}}>
      <InputLabel id="demo-simple-select-label">{props.title}</InputLabel>
      <Select
        disabled={!!props.disabled}
        onChange={props.onChange}
        value={props.defaultValue}
      >
        {props.options.map((opt) => {
          return <MenuItem disabled={opt.Disabled} value={opt._configId}>{opt.Name}</MenuItem>
        })}
      </Select>
    </FormControl>
  )
}