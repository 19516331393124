import React, { useEffect, useState } from 'react';

import {RequestQuoteView} from './styled';

import flex from '../../flex';

import {PriceTag, DefaultButton, YellowButton} from './../Buttons/styled';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import ContactForm from './ContactForm';



export default () => {

  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [showCTA, setShowCTA] = useState<boolean>(true)

  useEffect(() => {
    const reload = () => {
      setTotalPrice(flex.calculatePrice())
    }
    flex.ready().then(reload);
    flex.subscribe(flex.events.ResourceEnabled, reload);
    flex.subscribe(flex.events.ResourceDisabled, reload);
    flex.subscribe(flex.events.ResourceGroupEnabled, reload);
    flex.subscribe(flex.events.ResourceGroupDisabled, reload);
    return () => {
      flex.unSubscribe(flex.events.ResourceEnabled, reload);
      flex.unSubscribe(flex.events.ResourceDisabled, reload);
      flex.unSubscribe(flex.events.ResourceGroupEnabled, reload);
      flex.unSubscribe(flex.events.ResourceGroupDisabled, reload);
    }
  }, []);

  return (
    <RequestQuoteView>

      <Container style={{height:"100%"}}>

        <Grid container direction="row" justify="space-between" alignItems="center" style={{height:"100%"}} >

       
          <Grid item xs={6} md={6} style={{background:"#f3f3f3", padding:"6px", borderRadius:"8px",boxShadow:"0px 1px 2px #555"}}>
            <Grid container direction="column" justify="center" alignItems="center" >
                <Grid item>
                  <span style={{fontSize:"90%"}}>Hinta-arvio</span>
                </Grid>
                <Grid item>
             
                    <div style={{borderBottom:"1px dashed #333", fontSize:"120%", fontWeight:"bold"}}>{totalPrice} € / kk</div>
       
                </Grid>
              </Grid>
          </Grid>
          <Grid item xs={6} md={6} style={{textAlign:"right"}}>
            {showCTA && totalPrice > 0 && (
              <DefaultButton
                onClick={() => {
                  setShowCTA(false);
                  flex.publish(flex.events.OpenSlideModal, {
                    openFrom: "bottom",
                    width: 100,
                    component: <ContactForm/>,
                    closeHandler: () => {
                      setShowCTA(true);
                      console.log('closed')
                    }
                  });
                  flex.publish(flex.events.LogEvent, { event: "FormOpened" });
                }}
              >
                {window.innerWidth > 500 ? 'Lähetä tarjouspyyntö' : 'Tarjouspyyntö'}</DefaultButton>
            )}
            {(!showCTA || totalPrice === 0) && (
              <>

                Tarvitsetko apua?
                <br/>
                <a href="tel:+358102921640">Soita 010 292 1640</a>

              </>
            )}
            
          </Grid>

        </Grid>

      </Container>

      
      

    
      
    </RequestQuoteView>
  )
}