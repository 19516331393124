import styled, {keyframes} from 'styled-components';

import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { ArrowFunction } from 'typescript';


const closeIconCss = `
  && {
    font-size:40px;
    cursor:pointer;
    &:hover {
      color:#007BFF;
    }
  }
`;

export const CloseIconUp = styled(ArrowUpwardIcon)`${closeIconCss}`;
export const CloseIconDown = styled(ArrowDownwardIcon)`${closeIconCss}`;
export const CloseIconBack = styled(ArrowBackIcon)`${closeIconCss}`;
export const CloseIconForward = styled(ArrowForwardIcon)`${closeIconCss}`;
export const CloseIconX = styled(HighlightOffIcon)`${closeIconCss}`;

const FadeIn = keyframes`
  0% { opacity: 0;}
  100% { opacity: 0.5;}
`;
const FadeOut = keyframes`
  0% { opacity: 0.5;}
  100% { opacity: 0;}
`;
export const OverlayBackground = styled.div<{
  reverse: boolean,
  animate: boolean,
  containerHeight: string,
}>`
  position:fixed;
  top:0px;
  left:0px;
  width:100%;
  height:${props => props.containerHeight};
  background:#333;
  opacity: ${props => (!props.reverse) ?  '0.5' : '0' };
  animation-name: ${props => (!props.animate) ? 'none' : (!props.reverse) ?  FadeIn : FadeOut };
  animation-timing-function: ease-out;
  animation-duration: 0.25s;
`;

const SlideIn = (from: string, start: number, end: number) => keyframes`
  0% { ${from}: ${start}%;}
  100% { ${from}: ${end}%;}
`;
const SlideOut = (from: string, start: number, end: number) => keyframes`
  0% { ${from}: ${start}%;}
  100% { ${from}: ${end}%;}
`;

export const SlideFrom = styled.div<{
  from: 'bottom' | 'right' | 'top' | 'left' | string,
  reverse: boolean,
  animate: boolean,
  start: number,
  end: number,
  width: number,
  containerHeight:string,
}>`
  position:fixed;
  overflow-y: auto;
  ${props => (props.from === 'bottom' || props.from === 'top') ? 'left' : 'top'}:0px;
  ${props => (props.from === 'right' || props.from === 'left') ? 'width' : 'height'}: ${props => props.width}%;
  ${props => (props.from === 'right' || props.from === 'left') ? 'height: ' + props.containerHeight : 'width:100%'};
  box-sizing:border-box;
  background:#fff;
  
  background:#f3f3f3;
  ${props => props.from}: ${ props => (props.reverse) ? props.start : props.end}%; 
  animation-name: ${props => (!props.animate) ? 'none' : (!props.reverse) ?  SlideIn(props.from, props.start, props.end) : SlideOut(props.from, props.end, props.start) };
  animation-direction: normal;
  animation-timing-function: ease-out;
  animation-duration: 0.35s;
  opacity:1;

  padding:20px 20px 15vh 20px;
  @media (max-height: 500px) {
    padding-bottom:25vh;
  }
  

  
`;

