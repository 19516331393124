/*
  Does not support range
*/

import React, {useState} from 'react';

import styled from 'styled-components';

import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';


const NumberField = (props: {
  key : string,
  defaultValue: number,
  title: string,
  max: number | undefined,
  min: number | undefined,
  step: number | undefined,
  error?: string,
  onChange: (event: any, value: number) => void
}) => {
  const [val, setVal] = useState(props.defaultValue || 10)
  const resetState = () => {
    setVal(props.defaultValue || 10)
  }

  return (

        <ButtonGroup key={props.key}>
          <TextField
            error={props.error ? true : false}
            helperText={props.error}
            type="number"
            label={props.title}
            variant="filled"
            defaultValue={val}
            value={val}
            onChange={(ev: any) => {
              let newVal = Number(ev.target.value);
              props.onChange(ev, newVal);
              setVal(newVal)

            }}
          />
          <Button
            aria-label="reduce"
            style={{color:"red",fontSize:"32px"}}
            size="large"
            onClick={() => {
              setVal(Math.max( Number(val) - 1, props.min || 1));
              props.onChange(null, val - 1);
              
              
            }}
          >
            <RemoveIcon fontSize="small" />
          </Button>
          <Button
            aria-label="increase"
            style={{color:"green"}}
            size="large"
            onClick={() => {
              setVal(Math.min(Number(val) + 1, props.max || 999999999999999));
              props.onChange(null, val + 1);
              
              
            }}
          >
            <AddIcon fontSize="small" />
          </Button>
        </ButtonGroup>

  )
}
export default NumberField;